@import '../../assets/variables';

.Divider {
	display: flex;
	align-items: center;
	text-align: center;
	width: 100%;

	p {
		margin-left: 5px;
		margin-right: 5px;
		font-family: $global-font;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 20px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: $grey;
	}
}

.Divider::before,
.Divider::after {
	content: '';
	flex: 1;
	border-bottom: 1px solid $light-grey;
}

.Divider:not(:empty)::before {
	margin-right: 0.25em;
}

.Divider:not(:empty)::after {
	margin-left: 0.25em;
}
