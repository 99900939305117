@import '../../assets/variables';
.__Input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 15px;
  span {
    margin: 5px 0 10px 0;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
  }
  label {
    font-size: 14px;
    letter-spacing: 0.02rem;
    margin: 0 0 5px 0;
    font-weight: 600;
    text-transform: uppercase;
    color: $black;
    font-family: $global-font;
  }
}
.__Input {
  width: 100%;
  height: 42px;
  background: #ffffff;
  padding: 10px;
  /* Design/Outline/Main */
  border: 1px solid $light-blue;
  box-sizing: border-box;
  /* Input */
  // box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: $global-font;
  @media only screen and (max-width: $break-point) {
    font-size: 15px;
  }
  &:focus {
    border: 1px solid $brand-color;
  }
  &:disabled {
    background: #f4f5f4;
    opacity: 0.5;
    cursor: not-allowed;
  }
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  cursor: pointer;
}
.__Input-dropdown-icon {
  position: absolute;
  right: 18px;
  top: 33px;
  color: $light-blue;
  pointer-events: none;
}
.__Input-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;
  svg {
    position: absolute;
    right: 20px;
    top: 32px;
    width: 20px;
    z-index: 999;
    color: $light-blue;
    cursor: pointer;
  }
  .__Input-container {
    width: 100%;
    input {
    }
  }
}
.__Input-error {
  border-color: $red;
}
.__Input-success {
}
.__Input-error-message {
  color: $red;
  font-family: $global-font;
}
.__Input-success-message {
  color: $green;
  font-family: $global-font;
}
.__Input-button {
  cursor: pointer;
  position: absolute;
  width: 68px;
  left: 274px;
  top: 10px;
  background: $brand-color;
  color: white;
  border-radius: 0px 4px 4px 0px;
  border-width: 0;
  height: 42px;
  &:disabled {
    background: $grey;
  }
  &:focus {
    border: none;
  }
}
