@import '../../assets/variables';

button {
	user-select: none !important;
}

.Button {
	// flex proeprties
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	// border
	border: 1px solid $brand-color;
	border-radius: 42px;
	// font related
	font-family: $global-font;
	font-weight: 700;
	line-height: 17.6px;
	text-transform: uppercase;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	margin: 5px 0;
	letter-spacing: 1px;

	&__primary {
		background-color: $brand-color;
		color: white;

		&:disabled {
			background-color: $light-grey;
			border: 1px solid $light-grey;
		}
	}

	&__danger {
		background-color: $red;
		border: 1px solid $red;
		color: #ffffff;

		&:disabled {
			background-color: $light-grey;
			border: 1px solid $light-grey;
		}
	}

	&__secondary {
		background-color: white;
		color: $brand-color;
		&:disabled {
			color: $light-grey;
			border: 1px solid $light-grey;
		}
	}

	&__sm {
		min-width: 100%;
		height: 24px;
		font-size: 11px;

		svg {
			width: 14px;
			padding-top: 2px;
		}
	}

	&__md {
		min-width: 100%;
		height: 34px;
		font-size: 13px;
	}

	&__lg {
		min-width: 100%;
		height: 42px;
		font-size: 14px;
	}

	svg {
		width: 20px;
		padding: 0px 5px 5px 5px;
	}
}

