.Button {
	&__pagination {
		position: absolute;
		min-width: 100px !important;

		svg {
			margin-top: 5px;
			width: 80px !important;
		}

		&--left {
			left: 10px;
		}

		&--right {
			right: 10px;
		}
	}
}

.NotificationListItem {
	position: relative;
	cursor: pointer;
	padding: 13px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	align-items: flex-start;
	letter-spacing: 0.02em;

	.title {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 24px;
		color: $black;
	}

	.date {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		color: $grey;
	}

	.data {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.__deleteIcon {
		cursor: pointer;
		color: $red;
		position: absolute;
		right: 15px;
		top: 23px;
		z-index: 15;
		svg {
			width: 20px;
		}
	}
}

.OrdersTicketItemContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;
	padding-top: 0;

	svg {
		width: 25px !important;
		margin-top: 4px;
		margin-right: 5px;
	}

	.OrdersTicketItem {
		width: 100%;
		margin: 10px 0;

		.OrdersTicketItemHeader {
			display: flex;
			justify-content: space-between;
			margin-bottom: 5px;

			.lineThrough {
				text-decoration: line-through;
			}

			span {
				font-style: normal;
				display: flex;
				align-items: center;

				&:nth-child(1) {
					letter-spacing: 0.02em;
					text-transform: uppercase;
					font-weight: bold;
					font-size: 18px;
					line-height: 23px;
				}

				&:nth-child(2) {
					font-size: 18px;
					line-height: 19px;
					letter-spacing: 0.02em;
				}
			}
		}

		.OrdersTicketItemTickets {
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			width: 100%;
			letter-spacing: 0.02em;
			margin: 3px 0;

			div {
				color: $grey;

				&:nth-child(1) {
					font-weight: 600;
					font-size: 14px;
					line-height: 22px;
					letter-spacing: 0.02em;
				}

				&:nth-child(2) {
					font-size: 14px;
					line-height: 22px;
					letter-spacing: 0.02em;
				}
			}
		}
	}
}

.Prompt {
	padding: 20px;
	background: white;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
	max-width: 300px;
	border-radius: 10px;

	&.deletion {
		max-width: 400px;
	}

	div {
		display: flex;
		justify-content: center;
		flex-direction: column;

		p {
			margin: 25px 0;
		}

		button {
			min-width: auto !important;
			margin: 10px 20px;
		}
	}

	&__container {
		display: flex !important;
		justify-content: space-between !important;
		flex-direction: row !important;
		padding: 0 20px;

		p {
			color: $brand-color;
			cursor: pointer;
		}
	}
}

// Accordion
.help-accordion-heading {
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.02em;
	color: $black;
	padding-right: 40px;
}

.accordion-item {
	position: absolute;
	top: 12px;
	right: 15px;
	color: $grey;

	svg {
		width: 20px;
	}
}

.accordion__button {
	position: relative;
	padding: 13px 0;
	margin: -13px 0;
}

.help-accordion-panel {
	font-style: normal;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	color: $black;
	letter-spacing: 0.02em;
	padding-right: 55px;
	border-top: 1px solid $light-grey;
}
