@import '../../../assets/variables';
.PSM {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .disabled {
    background: #becad4 !important;
  }
  span {
    width: 32%;
    min-height: 5px;
    border-radius: 90px;
    &:nth-child(1) {
      background: #de210a;
    }
    &:nth-child(2) {
      background: #e8a005;
    }
    &:nth-child(3) {
      background: #75be21;
    }
  }
}
