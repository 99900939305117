@import '../../assets/variables';
@import '../../assets/keyframes';

.Alert {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 16px 0 16px;
	width: 100%;
	box-sizing: border-box;
	border-radius: 8px;
	margin: 15px 0;

	div {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-family: $global-font;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		margin-left: 10px;

		svg {
			margin-right: 10px;
		}

		h1 {
			justify-self: center;
		}
	}

	&.success {
		background: $light-green;
		border: 1px solid $green;

		p,
		svg {
			color: $green;
		}
	}

	&.warning {
		background: $light-yellow;
		border: 1px solid $yellow;

		svg,
		p {
			color: $yellow;
		}
	}

	&.error {
		background: $light-red;
		border: 1px solid $red;

		svg,
		p {
			color: $red;
		}
	}

	&.float {
		position: absolute;
		top: 25%;
		left: 50%;
		margin: auto;
		max-width: 500px;
		transform: translate( -50%, -25% );
		padding: 20px 15px;

		div {
			display: block;
			margin-left: 0;

			h1 {
				text-align: center;
			}
		}

		@media only screen and ( max-width: 500px ) {
			max-width: 300px;
		}
	}

	&.hide {
		-webkit-animation: fadeout 0.3s linear forwards;
		animation: fadeout 0.3s linear forwards;
	}

	&.show {
		-webkit-animation: fadein 0.3s linear forwards;
		animation: fadein 0.3s linear forwards;
	}

	&__close {
		background-color: transparent;
		border: none;
		cursor: pointer;

		svg {
			color: $red;
			width: 20px;
		}
	}
}
