@import '../../../assets/variables';

.VerticalMenu {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	&:hover {
		cursor: pointer;
	}

	svg {
		color: $grey;
		max-width: 16px;
	}

	p {
		font-family: $global-font;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		margin-left: 15px;
		text-transform: capitalize;
		user-select: none;
	}

	&__item {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $light-grey;
		padding: 5px 20px;

		div {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}

		span {
			display: flex;
		}

		&.last {
			border-bottom: none;
		}

		&.disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}

		&--badge {
			position: absolute;
			top: 12px;
			left: 12px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 2px 3px;
			width: 17px;
			height: 17px;
			background: #fb4e4e;
			border-radius: 50%;
			// font
			font-family: $global-font;
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 15px;
			color: #ffffff;
		}

		&__threesection {
			width: 100%;

			p:nth-child(1) {
				width: 65%;
				margin-left: 0;

				a {
					text-decoration: underline;
					color: $brand-color;
				}
			}

			p:nth-child(2) {
				display: flex;
				justify-content: center;
				width: 15%;
			}

			p:nth-child(3) {
				display: flex;
				justify-content: flex-end;
				width: 20%;
			}
		}
	}
}
