@import '../../assets/variables';

.Loader {
	left: 0;
	top: 0;
	display: block;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1000;

	span {
		margin: 20px 0;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 22px;
		font-family: $global-font;
	}

	&.full {
		background: white;
	}

	&.overlay {
		position: absolute;
		background: rgba(255, 255, 255, .8);
		border-radius: 8px;
	}

	&.hide {
		display: none;
	}

	&__inner {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		position: absolute;
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&__spinner {
		width: 75px;
		height: 75px;
		display: inline-block;
		border-width: 2px;
		border-color: rgba(255, 255, 255, 0.05);
		border-top-color: $brand-color;
		animation: spin 1s infinite linear;
		border-radius: 100%;
		border-style: solid;
	}
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}
