// colors
@import './brand';
$black: #2b2d2e;
$grey: #4d5152;
$light-blue: #7992ab;
$red: #de210a;
$light-red: #f2d8d4;
$green: #75be21;
$light-green: #e5f3d5;
$light-grey: #e2e5e6;
$mid-grey: #7d8385;
$yellow: #e8a005;
$light-yellow: #fdf6e6;
$brand-color-grey: #ecf0f1;
