@import '../../../assets/variables';

.TagList {
	display: flex;
	width: 100%;
	overflow-x: scroll;
	padding-bottom: 10px;
	margin-bottom: 10px;

	/* width */
	&::-webkit-scrollbar {
		width: 3px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: $light-grey;
		border-radius: 10px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: $light-blue;
		border-radius: 10px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: $brand-color;
	}

	&__item {
		min-width: 150px;
		margin: 2px 10px 2px 0;
		/* Auto Layout */
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 8px 10px;
		height: 31px;
		background: #ffffff;
		/* Design/Outline/Main */
		border: 1px solid $light-blue;
		box-sizing: border-box;
		border-radius: 8px;
		cursor: pointer;

		button {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: transparent;
			border: none;
			color: white;
			margin-left: 0px;
			padding-right: 0;
			cursor: pointer;

			svg {
				width: 16px;
			}
		}

		p {
			font-family: $global-font;
			text-transform: capitalize;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 15px;
		}

		&.active {
			background-color: $brand-color !important;
			color: white !important;
		}
	}
}
