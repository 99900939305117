@import './variables';
@import './fonts';
// global styles
*,
*::before,
*::after {
	box-sizing: border-box;
}
html {
	height: 100%;
}
iframe {
	position: initial !important;
}
body {
	height: 100%;
	font-family: $global-font;
	margin: 0 !important;
	background-color: #ecf0f1;
	overflow: initial !important;
}
h1,
h2,
h3,
p,
span {
	user-select: none !important;
}
#root {
	height: 100%;
}
nav {
	width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	background: white;
	position: fixed;
	bottom: 0;
	z-index: 9999;
}
form {
	width: 100%;
	justify-content: center;
	display: flex;
	flex-direction: column;
	margin: 20px;
}
a {
	color: $brand-color;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 22px;
	letter-spacing: 0.02em;
	text-decoration-line: underline;
}
section {
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 70px;
	margin-bottom: 100px;
}
.__Custom-logo-bottom {
	position: absolute;
	bottom: 100px;
	left: 0;
	right: 0;
	margin: auto;
}
.__a-bold {
	color: black;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 10px;
}
.row {
	display: flex;
	flex: 0 1 auto;
	flex-direction: row;
	flex-wrap: wrap;
	@media only screen and (max-width: $break-point) {
		flex-direction: column;
	}
	display: flex;
	justify-content: space-between;
	.__Input-container {
		width: 49%;
		@media only screen and (max-width: $break-point) {
			width: 100%;
		}
	}
}
.react-confirm-alert-overlay {
	background: #ffffffb3;
}
.autocomplete {
	position: absolute;
	z-index: 10000;
	font-size: 14px;
	font-family: $global-font;
	width: 100%;
	background-color: white;
	top: 70px;
	border-radius: 4px;
	border: 1px solid $brand-color;
	div {
		cursor: pointer;
		padding: 10px;
	}
}
.search-close-icon {
	position: absolute;
	top: 8px;
	right: 15px;
	cursor: pointer;
	color: $brand-color;
}

.react-switch-bg {
	background: $brand-color !important;
}
// commponent imports
@import '../components/Button/button';
@import '../components/Input/input';
@import '../components/Alert/alert';
@import '../components/CheckBox/checkBox';
@import '../components/Divider/divider';
@import '../components/Card/card';
@import '../components/Loader/loader';
@import '../components/Page/page';
// Modular
@import '../components/modular/Counter/counter';
@import '../components/modular/TagList/tagList';
@import '../components/modular/VerticalMenu/verticalMenu';
@import '../components/modular/BottomNavigation/bottomNavigation';
@import '../components/modular/Header/header';
@import '../components/modular/Stepper/stepper';
@import '../components/modular/CartSummary/cartSummary';
@import '../components/modular/PasswordStrengthMeter/passwordStrengthMeter';
// misc
@import './accordion';
//
@import '../features/Start/start';
@import '../features/Shop/shop';
@import '../features/Settings/settings';
@import '../features/Authentication/auth';
