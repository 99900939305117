.TicketTypeContainer {

	display: flex;
	flex-direction: column;
	width: 100%;

	.TicketTypeHeader {
		display: flex;
		flex-direction: row;
		align-items: center;
		svg {
			margin: 10px;
			margin-top: 12px;
			width: 17px;
			cursor: pointer;
		}
	}

	.TicketTypeChild {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;

		.Card {
			display: flex;
			flex-direction: column;
			width: 48%;
			cursor: pointer;
			position: relative;

			.Divider {
				width: 80%;
			}

			.TicketTypeName {
				color: $black;
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				letter-spacing: 0.02em;
				margin: 0;
				justify-content: flex-start;
				display: flex;
				width: 100%;
				padding: 15px;
				font-style: normal;
			}

			.TicketTypePrice {
				display: flex;
				margin: 0;
				width: 100%;
				padding: 15px;
				font-size: 14px;
				justify-content: flex-end;
				align-items: center;
				line-height: 10px;
				color: #7d8385;

				span {
					color: $black;
					margin-left: 7px;
					font-weight: 500;
					font-size: 16px;
					letter-spacing: 0.02em;
				}
			}
		}
	}
}

.TicketDateContainer {
	width: 100%;

	.Card {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 40px;
		margin: 20px 0;
		border: 1px solid $light-blue;
		box-sizing: border-box;
		border-radius: 8px;

		p {
			margin: 0;
			color: $black;
			text-transform: uppercase;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.02em;
		}

		span {
			color: $mid-grey;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			letter-spacing: 0.02em;
		}
	}
}

.CustomDateBox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	background-color: white;
	padding: 10px;
	background: #ffffff;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	position: relative;

	.rdtPicker {
		bottom: 75px;
	}

	.rdt {
		cursor: pointer;
		user-select: none;
		width: 100%;
		padding: 15px 0px;

		div {
			width: 100%;

			h2 {
				margin: 0;
				width: 100%;
				display: flex;
				justify-content: flex-end;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: uppercase;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 18px;
				color: $brand-color;

				svg {
					width: 20px;
					margin-right: 10px;
				}
			}
		}
	}
}

.PaxCounterContainer {
	margin-bottom: 60px;

	.Card {
		display: flex;
		justify-content: space-between;
		padding: 5px 15px;
		margin-bottom: 20px;

		div {
			display: flex;
			flex-direction: column;
		}
	}

	h3 {
		line-height: 23px;
		margin: 0;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: $black;
	}

	p {
		margin: 0;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		align-items: center;
		letter-spacing: 0.02em;
		color: $mid-grey;
}

span {
		font-weight: bold;
		margin-top: 5px;
		font-size: 17px;
		align-items: center;
		letter-spacing: 0.02em;
		color: $grey;
	}
}

.PaxInfoContainer {
	width: 100%;
	margin-bottom: 80px;

	.Card {
		position: relative;
		padding: 0 20px;
		padding-bottom: 5px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		margin-top: 20px;

		.__deleteIcon {
			cursor: pointer;
			color: $red;
			position: absolute;
			right: 15px;
			top: 15px;

			svg {
				width: 18px;
			}
		}

		.__fotoUploader {
			width: 100%;
			margin-bottom: 10px;
			
			.__Alert {
				width: 100%;
			}

			span {
				font-size: 14px;
				letter-spacing: 0.02rem;
				margin: 0 0 5px 0;
				font-weight: 600;
				text-transform: uppercase;
				color: $black;
			}

			img {
				margin-top: 20px;
				max-width: 200px;
			}

			svg {
				width: 27px;
				margin: 3px 5px 0 0;
			}
		}

		.__Input-container {
			width: 100%;
		}

		h2 {
			font-weight: 800;
			font-size: 20px;
			display: flex;
			letter-spacing: 0.02em;
			text-transform: uppercase;
			width: 100%;
			color: $black;
		}
	}
}

.CustomBirthdayPicker {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	background-color: white;
	position: relative;
	width: 100%;

	.Button {
		position: absolute;
		right: 10px;
		top: 36px;
		z-index: 9999999;
		min-width: 20px !important;

		svg {
			width: 50px;
			margin-top: 4px;
		}
	}

	.rdtPicker {
		position: relative;
	}

	.rdt {
		cursor: pointer;
		user-select: none;
		width: 100%;
		padding: 15px 0px;

		div {
			width: 100%;

			h2 {
				margin: 0;
				width: 100%;
				display: flex;
				justify-content: flex-end;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: uppercase;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 18px;
				color: $brand-color;

				svg {
					width: 20px;
					margin-right: 10px;
				}
			}
		}
	}
}

.EmptyCartContainer {
	width: 100%;

	.Card {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		padding: 15px;

		p {
			margin: 0;
		}
	}
}

.CartContainer {
	width: 100%;

	.Card {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: 15px;

		.CartHeader {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
			align-items: center;
			margin-bottom: 15px;

			h1 {
				margin: 0;
				font-style: normal;
				font-weight: bold;
				font-size: 22px;
				letter-spacing: 0.02em;
				text-transform: uppercase;
			}

			button {
				min-width: 200px;

				svg {
					width: 30px;
					margin-top: 5px;
				}

				@media only screen and (max-width: $break-point) {
					min-width: 150px;
				}
			}
		}

		.CartBody {
			width: 100%;
			position: relative;

			.CartItem {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				&__left {
					width: 80%;

					margin-top: 10px;
					margin-bottom: 17px;

					@media only screen and (max-width: $break-point) {
						width: 70%;
					}

					&--container {
						display: flex;
						flex-direction: column;
						flex-wrap: wrap;

						> * {
							flex: 0 1 auto;
							max-width: 100px;
							margin-right: 20px;

							p,
							span {
								overflow-wrap: break-word;
							}
						}
					}

					h1 {
						margin: 0;
						margin-bottom: 10px;
						font-style: normal;
						font-weight: bold;
						font-size: 20px;
						letter-spacing: 0.02em;
						text-transform: uppercase;
					}

					p {
						margin: 0;
						font-style: normal;
						font-weight: 100;
						font-size: 14px;
						letter-spacing: 0.02em;
						text-transform: uppercase;
					}

					span {
						font-size: 14px;
						color: $mid-grey;
					}
				}

				&__right {
					width: 20%;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: flex-start;
					margin-top: 10px;

					span {
						text-align: center;
						font-size: 12px;
						color: $mid-grey;
					}

					h1 {
						margin: 0;
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						letter-spacing: 0.02em;
					}

					&--deleteIcon {
						cursor: pointer;
						color: $red;
						margin-top: 0px;

						svg {
							width: 18px;
						}
					}

					@media only screen and (max-width: $break-point) {
						width: 30%;
					}
				}
			}
		}

		.CartSummary {
			display: flex;
			padding: 0;
			width: 100%;
			position: unset;
			flex-direction: row;
			justify-content: space-between;
			background-color: transparent;

			p {
				font-style: normal;
				font-weight: bold;
				font-size: 22px;
				letter-spacing: 0.02em;
				line-height: 28px;
				color: $brand-color;
			}
		}

		.CartFooter {
			margin-top: 15px;
			width: 100%;
		}

		.CartCTA {
			width: 100%;

			button {
				margin-top: 20px;
			}
		}
	}
}

.PaymentContainerOverlay {
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 99999;
	width: 100%;
	height: 128%;
	background-color: #000000ba;

	h2 {
		color: white;
	}
}

.PaymentContainer {
	width: 100%;

	.Card {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	.CartHeader {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		padding: 15px;

		h1 {
			margin: 0;
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			letter-spacing: 0.02em;
			text-transform: uppercase;
		}

		button {
			min-width: 200px;

			svg {
				width: 30px;
				margin-top: 5px;
			}

			@media only screen and (max-width: $break-point) {
				min-width: 150px;
			}
		}
	}

	.CartBody {
		width: 100%;
		position: relative;

		.CartItem {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 10px 15px;

			&__left {
				flex-wrap: wrap;
				display: flex;
				width: 75%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;

				b {
					font-weight: 600;
				}

				h1 {
					margin: 5px 0;
					font-style: normal;
					font-weight: 100;
					font-size: 14px;
					letter-spacing: 0.02em;
					text-transform: uppercase;
					margin-right: 15px;
				}

				h3 {
					margin: 0;
				}

				p {
					margin: 5px 0;
					font-style: normal;
					font-weight: 100;
					font-size: 14px;
					color: $mid-grey;
				}

				span {
					font-size: 14px;
					color: $mid-grey;
				}

				@media only screen and (max-width: $break-point) {
					width: 70%;
				}
			}

			&__right {
				width: 25%;
				display: flex;
				align-items: flex-end;
				flex-direction: column;
				justify-content: flex-start;
				font-family: $secondary-font;

				h1 {
					margin: 0;
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					letter-spacing: 0.02em;
				}

				h2,
				h3 {
					margin: 5px 0 0;
					line-height: 16px;
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					color: $mid-grey;
					letter-spacing: 0.02em;
				}

				h3 {
					color: $black;
				}

				p {
					margin: 0;
				}

				&--deleteIcon {
					cursor: pointer;
					color: $red;
					margin-top: 2px;

					svg {
						width: 18px;
					}
				}

				@media only screen and (max-width: $break-point) {
					width: 30%;
				}
			}
		}

	}

	.CartSummary {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		margin: 10px 0;
		margin-top: 0;

		p {
			margin: 0;
			font-style: normal;
			letter-spacing: 0.02em;

			h1 {
				margin: 0;
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 22px;
				letter-spacing: 0.02em;
			}

			span {
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 22px;
				letter-spacing: 0.02em;
				color: $mid-grey;
			}
		}
	}

	.CartSummaryToPay {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding: 10px 15px;

		p,
		h1 {
			margin: 0;
		}

		h1 {
			text-transform: uppercase;
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			letter-spacing: 0.02em;
		}

		span {
			margin: 0;
			font-style: normal;
			font-weight: bold;
			font-size: 22px;
			letter-spacing: 0.02em;
		}
	}

	.BillingInfo {
		display: flex;
		width: 100%;
		flex-direction: column;
		padding: 20px 15px 10px 15px;

		h1 {
			margin-bottom: 5px;
			font-size: 22px;
			text-transform: uppercase;
		}

		p {
			margin-top: 0px;
			margin-bottom: 0;
			color: $mid-grey;
		}
	}

	.PaymentOptions {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 10px 15px;

		.StripeElement {
			padding: 15px 0;
		}

		h1 {
			font-size: 22px;
			text-transform: uppercase;
			margin-bottom: 0px;
		}

		ul {
			padding: 0;
			list-style-type: none;

			li {
				user-select: none;
				margin-top: 20px;
				cursor: pointer;
				font-weight: 500;
				font-size: 18px;
				letter-spacing: 0.02em;
				padding-bottom: 10px;
				position: relative;

				svg {
					width: 20px;
					color: $brand-color;
					position: absolute;
					right: 0;
				}
			}
		}
	}
}

.MyTicketsContainer {
	width: 100%;

	&__header {
		padding: 0 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		:nth-child(2) {
			margin-left: 50px;
		}
	}

	.Card {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.CartBody {
			width: 100%;
			position: relative;

			.CartItem {
				padding: 0 15px;
				margin: 10px 0;
				display: flex;
				flex-direction: row;
				border-left: 4px solid;

				&__expired {
					border-color: $red;

					.CartItem__center {
						h1 {
							color: $red !important;
						}
					}
				}

				&__active {
					border-color: $green;
				}

				&__inactive {
					border-color: $yellow;
				}

				&__left {
					width: 50%;
					margin-top: 10px;
					margin-bottom: 17px;
					cursor: pointer;

					h1 {
						margin: 5px 0;
						max-width: 230px;
						font-style: normal;
						font-weight: bold;
						font-size: 16px;
						letter-spacing: 0.02em;
						text-transform: uppercase;
						word-break: break-word;
					}

					p {
						margin: 5px 0;
						font-style: normal;
						font-weight: 100;
						font-size: 14px;
						letter-spacing: 0.02em;
						text-transform: uppercase;
					}

					span {
						font-size: 14px;
						color: $mid-grey;
					}

					&.inactive {
						opacity: 0.5;
					}
				}

				&__center {
					display: flex;
					flex-direction: row;
					cursor: pointer;
					width: 50%;

					.active-info {
						width: 60%;

						h1 {
							color: $green;
							text-transform: uppercase;
							font-size: 18px;
							line-height: 24px;
							margin-bottom: 5px;
							margin-top: 12px;
						}

						p {
							font-size: 14px;
							line-height: 22px;
							margin: 0;
						}
					}

					.active {
						position: relative;
						width: 40%;
						max-width: 80px;
						margin-left: auto;

						button {
							svg {
								width: 25px;
								margin-top: 5px;
							}
						}
					}
				}

				&__right {
					justify-content: center;
					align-items: flex-start;
					margin-top: 10px;
					width: 50%;

					.not-activated {
						position: relative;
						justify-content: start;

						button {
							width: 100%;
							padding: 10px 40px;
						}
					}
				}
			}
		}

		.CartFooter {
			padding: 15px 15px;
			width: 100%;

			b {
				font-weight: 600;
				font-size: 15px;
				color: $black;
			}

			p {
				font-weight: 500;
				font-size: 14px;
				margin-bottom: 10px;
				margin-top: 10px;

				a {
					font-size: 14px;
					color: $brand-color;
				}
			}
		}
	}
}

.MyTicketsDetailContainer {
	padding-top: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	background-color: transparent;
	opacity: 0.999999;
	width: 100%;
	overflow: hidden;

	.backdrop {
		position: absolute;
		width: 100%;
		height: 127%;
		z-index: -1;
		top: 0;

		&__active {
			background: rgb(127, 183, 63);
			background: linear-gradient(
				0deg,
				rgba(127, 183, 63, 1) 60%,
				rgba(0, 0, 0, 0) 100%
			);
		}

		&__inactive {
			background: rgba(250, 193, 59, 1);
			background: linear-gradient(
				0deg,
				rgba(250, 193, 59, 1) 60%,
				rgba(0, 0, 0, 0) 100%
			);
		}

		&__expired {
			background: rgba(231, 52, 40, 1);
			background: linear-gradient(
				0deg,
				rgba(231, 52, 40, 1) 60%,
				rgba(0, 0, 0, 0) 100%
			);
		}
	}

	.animation-gif {
		position: absolute;
		background-image: url('../../assets/Animation.gif');
		background-size: cover;
		width: 100%;
		height: 35%;
		top: 0;
		z-index: -2;
		opacity: 0.8;
	}

	.qr-code-animation {
		max-width: 40%;
	}

	button {
		min-width: 40%;
		margin: 20px;

		svg {
			width: 25px;
			margin-top: 5px;
		}
	}

	span {
		display: flex;
		align-items: baseline;
		color: #ffffff;
		letter-spacing: 0.02em;
		text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

		small {
			font-weight: 300;
			margin-left: 0.5rem;
		}
	}

	.activateSince {
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 10px;
	}

	.show-identity {
		display: flex;
		align-self: end;
		color: #fff;
		padding: 15px;

		.sidebar-icon {
			align-self: center;
			margin-left: 10px;
		}
	}

	.text-info{
		width: 100%;
		padding-left: 15px;
		margin-bottom: 50px;

		span:nth-child(1) {
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;
		}

		span:nth-child(2) {
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.02em;
			text-transform: uppercase;
		}

		span:nth-child(3) {
			font-size: 14px;
			line-height: 22px;
		}
	}
}

.VoucherContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px 15px;

	.__fotoUploader {
		.Button {
			position: relative;

			svg {
				position: absolute;
				left: 10px;
				top: 6px;
				width: 25px !important;
			}
		}
	}

	.__Input-container {
		margin-top: 10px;

		.__Input-button {
			right: 0;
			top: 0;
			left: auto;
		}
	}

	span {
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0.02rem;
		margin-top: 0;
		margin-bottom: 1rem;
	}
}

.autocomplete {
	z-index: 900000;
}

.pricetag {
	font-family: $secondary-font !important;
}
