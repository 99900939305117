.PasswordResetContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  width: 100%;
  .__Alert {
    width: 100%;
  }
}

.pw-reset-button {
	margin-bottom: 8px;
	border: none;
	background: none;
	text-decoration: underline;
}
