@import '../../../assets/variables';

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
}
.__Header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* Design/Background */
  background: #ecf0f1;
  /* Shadow as Divider */
  box-shadow: 0px 1px 0px #becad4;
  position: relative;

  .__Header-rightItems {
    position: absolute;
    right: 100px;
    top: 3px;
    button {
      background-color: inherit;
      cursor: inherit;
    }
    @media screen and (min-width: $break-point) {
      right: 35vw;
      left: 18vw;
    }
  }
  p {
    /* Headline/Section */
    font-family: $global-font;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /* identical to box height, or 120% */
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
  button {
    cursor: pointer;
    left: 0;
    top: 20px;
    position: absolute;
    border: 0;
    background: none;
    padding: 20px 32px;
    margin: -20px -5px;
    left: 29vw;
    @media screen and (max-width: $break-point) {
      left: 0;
    }
    @media screen and (min-width: $break-point-xl) {
      left: 35vw;
    }
    svg {
      width: 25px;
    }
  }
}
