@import '../../../assets/variables';

.BottomNavigation {

	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	height: 87px;
	background: white;
	padding: 20px 0;
	max-width: 720px;

	&__item {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		position: relative;
		width: 25%;
		color: $grey;

		&.active{
			svg,
			p {
				user-select: none;
				color: $brand-color;
			}
		}

		&.disabled {
			cursor: not-allowed !important;

			svg,
			p {
				user-select: none;
				color: $light-grey;
			}
		}

		&:hover {
			cursor: pointer;
		}

		span {
			// display
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 2px 3px;
			position: relative;
			margin-top: -15px;
			right: -17px;
			width: 17px;
			height: 17px;
			top: -14px;
			background: #fb4e4e;
			border-radius: 50%;
			// font
			font-family: $global-font;
			font-style: normal;
			font-weight: bold;
			font-size: 12px;
			line-height: 15px;
			color: #ffffff;
		}

		p {
			font-family: $global-font;
			font-style: normal;
			font-weight: 600;
			font-size: 10px;
			line-height: 13px;
			margin: 0;
			margin-top: 10px;
			/* identical to box height */
			display: flex;
			align-items: center;
			text-align: center;
			letter-spacing: 0.06em;
			text-transform: uppercase;
		}
	}
}
