.accordion {
  width: 100%;
  user-select: none;
  .accordion__item {
    padding: 0 16px;
    border-bottom: 1px solid $light-grey;
    .accordion__heading {
      padding: 13px 0;
      color: $black;
      font-size: 13px;
      font-weight: bold;
      display: inline-block;
      font-weight: bold;
      width: 100%;
      &:hover {
        cursor: pointer;
      }
      .accordion__button {
        font-size: 18px;
      }
    }
    .accordion__panel {
      font-style: normal;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: $black;
      letter-spacing: 0.02em;
      border-top: 1px solid $light-grey;
    }
  }
}
