@import '../../assets/variables';

.Card {
	// overflow: auto;
	word-wrap: break-word;
	/* Auto Layout */
	display: flex;
	position: relative;
	flex-direction: row;
	align-items: center;
	font-family: $global-font;
	width: 100%;
	height: auto;
	background: #ffffff;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	/* Inside Auto Layout */
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 10px 0;
}

.CardDisabled {
	.Card {
		opacity: 0.3;
		cursor: not-allowed !important;
		user-select: none;
	}
}
