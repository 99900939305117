@import '../../../assets/variables';

.__Counter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row !important;
  margin: 20px;
  .__Input-container {
    margin: 0;
  }
  svg {
    padding: 2px;
  }
}

.__Counter-button {
  min-width: 20px;
  border-radius: 0;
  height: 35px;
}
.__Counter-button-minus {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.__Counter-button-plus {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.__Counter-input {
  border-radius: 0;
  height: 35px;
  cursor: default;
  pointer-events: none;
  user-select: none;
  width: 40px;
  font-size: 18px;
  text-align: center;
  font-weight: 900;
  box-shadow: none !important;
  border-color: $light-grey;
}
