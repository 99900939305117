@import '../../assets/variables';

.__CheckBox {
  font-family: $global-font;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 7px 0;
  &:hover {
    cursor: pointer;
  }
  svg {
    color: white;
    width: 17px;
  }
  input {
    display: none;
  }
  span {
    cursor: pointer;
    user-select: none;
    font-family: $global-font;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02rem;
    color: $black;
  }
  .checkbox {
    min-width: 25px;
    height: 25px;
    border: 1px solid #8192a1;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px 0 0;
  }
}
.__CheckBox-checked {
  .checkbox {
    border: none;
    background-color: $brand-color;
  }
}
.__CheckBox-error {
  p {
    margin: 0;
    border-radius: 8px;
    padding: 5px;
    background: $light-red;
    border: 1px solid $red;
    color: $red;
    font-family: $global-font;
    font-size: 12px;
    margin-left: 10px;
  }
  .checkbox {
    border-color: $red;
  }
}
.__CheckBox-disabled {
  span {
    cursor: not-allowed;
    color: $grey;
  }
  .checkbox {
    cursor: not-allowed;
    background-color: #f4f5f4;
  }
}
