@import '../../../assets/variables';

.CartSummary {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	bottom: 87px;
	width: $universal-width;
	background-color: $brand-color;
	padding: 10px 30px;
	z-index: 999;

	span,
	p {
		font-family: $global-font;
		color: white;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
		/* identical to box height, or 157% */
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;
	}

	button {
		min-width: 150px !important;
	}

	&__total {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin-top: 5px;

		p,
		span {
			font-size: 16px;
			font-weight: bold;
		}

		p {
			margin: 0;
			font-weight: bold;
			margin-right: 5px;
		}
	}

	&__items {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	@media screen and (max-width: $break-point) {
		width: 100%;
	}

	@media screen and (min-width: $break-point-xl) {
		width: 30%;
	}
}
